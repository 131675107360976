<template>
  <div class="container">
    <loader />
  </div>
</template>
<script>
import NominationApi from '@/service/api'
import Loader from '../components/loader.vue'

export default {
  components: {
    Loader
  },
  data () {
    return {
      toastPosition: "top-right"
    }
  },
  created () {
    window.onresize = () => {
      this.toastPosition = window.innerWidth < 1064 ? "top" : "top-right"
    }
    // getting query params
    let query = this.$route.query
    if (query.scroll_top) {
      this.$store.dispatch('setScrollToTop', Number(query.scroll_top) > 0)
    }
    if (query.auto_grow) {
      this.$store.dispatch('setAllowAutoGrow', Number(query.auto_grow) >0)
    }
    if (query.award_id || query.award_family_id) {
      let obj = {
        awardId: query.award_id ? parseInt(query.award_id) : '',
        awardFamilyId: query.award_family_id ? parseInt(query.award_family_id) : ''
      }
      if ((!isNaN(obj.awardId) && obj.awardId) || (!isNaN(obj.awardFamilyId) && obj.awardFamilyId)) {
        this.$store.dispatch('setPreselectedAwardIds', obj)
      }
    }
    this.loginUsingSso()
  },
  methods: {
    async loginUsingSso () {
      this.showLoader = true
      const formData = {
        pin: this.$route.query.pin,
        domain: this.$route.query.domain,
        sso_token: this.$route.query.sso_token,
        random: this.$route.query.random,
        timestamp: this.$route.query.timestamp
      };
      NominationApi.login(formData)
      .then(res => {
        // change langauge from here
        if (res.data.subprogramConfigTheme) this.addTheme(res.data.subprogramConfigTheme)
        this.$root.$i18n.locale = res.data.language
        this.$store.dispatch('setUserData', res.data)
        this.$store.dispatch('setDomain', formData.domain)
        this.$store.dispatch('setTranslations', res.data.translations)
        // Just to call continue session before session expires
        let sessionTimeOut = Math.abs(res.data.sessionTimeoutAfter - 60)
        this.$store.dispatch('setContinueSessionInterval', sessionTimeOut)
        if (this.$route.path === '/') {
          this.$router.push({name: 'AwardStepper'})
        } else {
          this.$router.push({name: 'VideoPlayer'})
        }
      })
      .catch((error) => {
        this.showLoader = false
        this.$toast.open({
          message: error.response.data.error.details ? error.response.data.error.details : error.response.data.error.message,
          type: "error",
          position: this.toastPosition
        })
        if (error.response.status == 401) {
          this.$router.push({name: 'Unauthorized'})
        } else {
          this.$router.push({name: 'ServerError', query: { from: "login" }})
        }
      })
    },
    addTheme (theme) {
      if (!theme) return
      var r = document.querySelector(':root');
      if (theme.style_brand_primary_text) {
        r.style.setProperty('--primary-bg-text', theme.style_brand_primary_text);
      }
      if (theme.style_brand_primary) {
        r.style.setProperty('--primary', theme.style_brand_primary);
      }
      if (theme.style_brand_secondary) {
        r.style.setProperty('--secondary-bg', theme.style_brand_secondary);
        r.style.setProperty('--button-hover-bg', theme.style_brand_secondary);
      }
      if (theme.style_brand_secondary_text) {
        r.style.setProperty('--secondary-bg-text', theme.style_brand_secondary_text);
        r.style.setProperty('--button-hover-bg-text', theme.style_brand_secondary_text);
      }
    }
  }
}
</script>